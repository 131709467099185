var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            size: "normal",
            title: "入职事项",
            footer: null,
            closable: true,
          },
          on: {
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "v-content" },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "v-items" }, [
                _c("span", { staticClass: "v-title" }, [
                  _vm._v(_vm._s(item.entryMatterName)),
                ]),
                _c("span", { staticClass: "v-label-status" }, [
                  _c(
                    "span",
                    {
                      class: [
                        item.entryMatterStatus === 0 ? "warning" : "success",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.openSetting(item, index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          item.entryMatterStatus == 0 ? "待处理" : "已完成"
                        )
                      ),
                    ]
                  ),
                ]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "btn_box" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }